import React, { useEffect, useState } from "react";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { Col, Descriptions, Image, Row, Typography } from "antd";
import { ErrorRow } from "../../components/errorBlock";
import { SpinnerInline } from "../../components/spinners";
import { CopyableText } from "../../components/copyable";
import JSONPretty from "react-json-pretty";

function getTitleSE(texts = []) {
    for (const textsKey in texts) {
        if (texts[textsKey].Language === "SE") {
            return texts[textsKey].Title
        }
    }
}

export const ProductData = (props = { id: "", cached: false }) => {
    const JSONPrettyMon = require('react-json-pretty/dist/monikai');

    const [product, setProduct] = useState({
        Text: { Titles: [] },
        Product: {},
        Brand: {},
        Codes: {},
        Price: { Price: {}, PriceExtra: {} },
        Stock: {},
        Delivery: {},
    });
    const [productLegacy, setProductLegacy] = useState("");
    const [loadingProduct, setLoadingProduct] = useState(true);
    const [errProduct, setErrProduct] = useState('');

    let url = `/api/v1/products?sku=${props.id}`
    if (props.cached === true) {
        url = `/api/v1/products/cached?sku=${props.id}`
    }

    useEffect(() => {
        GetRequestInternalAPI(url, setLoadingProduct, setErrProduct, null)
            .then(
                (result) => {
                    if (result.Products.length > 0) {
                        if (result.Products[0].Text === undefined) {
                            result.Products[0].Text = { Titles: [] }
                        }
                        if (result.Products[0].Product === undefined) {
                            result.Products[0].Product = {}
                        }
                        if (result.Products[0].Brand === undefined) {
                            result.Products[0].Brand = {}
                        }
                        if (result.Products[0].Codes === undefined) {
                            result.Products[0].Codes = {}
                        }
                        if (result.Products[0].Price === undefined) {
                            result.Products[0].Price = { Price: {}, PriceExtra: {} }
                        }
                        if (result.Products[0].Stock === undefined) {
                            result.Products[0].Stock = {}
                        }
                        if (result.Products[0].Delivery === undefined) {
                            result.Products[0].Delivery = {}
                        }

                        setProduct(result.Products[0]);

                        if (result.ProductsLegacy[0] !== undefined) {
                            setProductLegacy(result.ProductsLegacy[0]);
                        }
                    }
                }
            )
        // eslint-disable-next-line
    }, []);

    if (errProduct !== "") {
        return <>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <ErrorRow prefix={"Product data"} err={errProduct} />
                    </Col>
                </Row>
            </div>
        </>
    }

    return (
        <>
            <div className="container-1700">
                {loadingProduct
                    ?
                    <SpinnerInline />
                    :
                    <Typography.Title
                        copyable={{ text: getTitleSE(product.Text.Titles) }}
                        level={3} style={{ margin: 0 }}>
                        {getTitleSE(product.Text.Titles)}
                    </Typography.Title>
                }
            </div>
            <div className="container-1700">
                {product.Product.Image !== undefined ? <Image style={{ maxWidth: 100 }} src={product.Product.Image} /> : <></>}
                {product.Brand.ID !== undefined ? <Image style={{ maxWidth: 100 }} src={`https://www.bangerhead.se/bilder/ibutik-a/varumarken/logo_liten/${product.Brand.ID}.gif`} /> : <></>}
            </div>
            <div className="container-1700">
                <Descriptions loading={loadingProduct}>
                    <Descriptions.Item label="SKU">{product.Product.ItemSKU}</Descriptions.Item>
                    <Descriptions.Item label="Created">{product.Product.Created}</Descriptions.Item>
                    <Descriptions.Item label="Updated">{product.UpdatedAt}</Descriptions.Item>
                    <Descriptions.Item label="Replica record updated">{product.RecordUpdatedAt}</Descriptions.Item>
                    <Descriptions.Item label="Groups">{product.Product.ProductGroups}</Descriptions.Item>
                </Descriptions>
            </div>
            <div className="container-1700">
                <Descriptions loading={loadingProduct}>
                    <Descriptions.Item label="Brand ID"><CopyableText text={product.Brand.ID} /></Descriptions.Item>
                    <Descriptions.Item label="Brand Name"><CopyableText text={product.Brand.Name} /></Descriptions.Item>
                    <Descriptions.Item label="Limit">{product.Brand.LimitLang}</Descriptions.Item>
                    <Descriptions.Item label="Visible">{product.Brand.IsVisible ? "YES" : "NO"} </Descriptions.Item>
                </Descriptions>
            </div>
            <div className="container-1700">
                <Descriptions loading={loadingProduct}>
                    <Descriptions.Item label="EAN"><CopyableText text={product.Codes.EAN} /></Descriptions.Item>
                    <Descriptions.Item label="MPN"><CopyableText text={product.Codes.MPN} /></Descriptions.Item>
                    <Descriptions.Item label="Barcode"><CopyableText text={product.Codes.Barcode} /></Descriptions.Item>
                </Descriptions>
            </div>
            <div className="container-1700">
                <Descriptions loading={loadingProduct}>
                    <Descriptions.Item label="Price"><CopyableText text={product.Price.Price.PriceStringified} /> {product.Price.Price.CurrencyCode}</Descriptions.Item>
                    <Descriptions.Item label="Price Extra"><CopyableText text={product.Price.PriceExtra.PriceStringified} /> {product.Price.PriceExtra.CurrencyCode}</Descriptions.Item>
                    <Descriptions.Item label="Purchase Price"><CopyableText text={product.Price.PurchasePrice} /> </Descriptions.Item>
                    <Descriptions.Item label="Is Set Price?">{product.Price.IsSetPrice ? "YES" : "NO"}</Descriptions.Item>
                    <Descriptions.Item label="Offer">{product.Price.Offer ? "YES" : "NO"}</Descriptions.Item>
                    <Descriptions.Item label="VAT ID">{product.Price.VatID}</Descriptions.Item>
                </Descriptions>
            </div>
            <div className="container-1700">
                <Descriptions>
                    <Descriptions.Item label="Stock ID"><CopyableText text={product.Stock.StockID} /></Descriptions.Item>
                    <Descriptions.Item label="Quantity"><CopyableText text={product.Stock.Quantity} /></Descriptions.Item>
                    <Descriptions.Item label="Supplier"><CopyableText text={product.Stock.SupplierID} /></Descriptions.Item>
                    <Descriptions.Item label="Shipping class ID"><CopyableText text={product.Stock.ShippingClassID} /></Descriptions.Item>
                    <Descriptions.Item label="Stock ID In Stock"><CopyableText text={product.Stock.StockIdInStock} /></Descriptions.Item>
                    <Descriptions.Item label="In stock?">{product.Price.InStock ? "YES" : "NO"}</Descriptions.Item>
                </Descriptions>
            </div>
            <div className="container-1700">
                <Descriptions>
                    <Descriptions.Item label="FreeShippingLimitSE"><CopyableText text={product.Delivery.FreeShippingLimitSE} /></Descriptions.Item>
                    <Descriptions.Item label="FreeShippingLimitNO"><CopyableText text={product.Delivery.FreeShippingLimitNO} /></Descriptions.Item>
                    <Descriptions.Item label="FreeShippingLimitFI"><CopyableText text={product.Delivery.FreeShippingLimitFI} /></Descriptions.Item>
                    <Descriptions.Item label="FreeShippingLimitNL"><CopyableText text={product.Delivery.FreeShippingLimitNL} /></Descriptions.Item>
                    <Descriptions.Item label="FreeShippingLimitDK"><CopyableText text={product.Delivery.FreeShippingLimitDK} /></Descriptions.Item>
                    <Descriptions.Item label="FreeShippingLimitPL"><CopyableText text={product.Delivery.FreeShippingLimitPL} /></Descriptions.Item>
                    <Descriptions.Item label="FreeShippingLimitBE"><CopyableText text={product.Delivery.FreeShippingLimitBE} /></Descriptions.Item>
                    <Descriptions.Item label="DeliveryCostSEK"><CopyableText text={product.Delivery.DeliveryCostSEK} /></Descriptions.Item>
                    <Descriptions.Item label="DeliveryCostNOK"><CopyableText text={product.Delivery.DeliveryCostNOK} /></Descriptions.Item>
                    <Descriptions.Item label="DeliveryCostDKK"><CopyableText text={product.Delivery.DeliveryCostDKK} /></Descriptions.Item>
                    <Descriptions.Item label="DeliveryCostEUR"><CopyableText text={product.Delivery.DeliveryCostEUR} /></Descriptions.Item>
                    <Descriptions.Item label="DeliveryCostEU3"><CopyableText text={product.Delivery.DeliveryCostEU3} /></Descriptions.Item>
                    <Descriptions.Item label="DeliveryCostPLN"><CopyableText text={product.Delivery.DeliveryCostPLN} /></Descriptions.Item>
                    <Descriptions.Item label="DeliveryCostEBE"><CopyableText text={product.Delivery.DeliveryCostEBE} /></Descriptions.Item>
                </Descriptions>
            </div>
            <div className="container-1700">
                <Descriptions>
                    {product.Text.Titles.map((Title) => (
                        <Descriptions.Item label={`Title ${Title.Language}`}><CopyableText text={Title.Title} /></Descriptions.Item>
                    ))}
                </Descriptions>
            </div>
            <div className="container-1700">
                <Typography.Title
                    level={3} style={{ margin: 0 }}>
                    Debug JSON version
                </Typography.Title>
            </div>
            <div className="container-1700">
                {<JSONPretty id="json-pretty" data={product} theme={JSONPrettyMon} />}
            </div>
            {
                props.cached === true ? <>
                    <div className="container-1700">
                        <Typography.Title
                            level={3} style={{ margin: 0 }}>
                            Debug JSON version (legacy)
                        </Typography.Title>
                    </div>
                    <div className="container-1700">
                        {<JSONPretty id="json-pretty" data={productLegacy} theme={JSONPrettyMon} />}
                    </div>
                </> : <></>
            }
        </>
    )
}
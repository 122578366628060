export const Services = {
    "auth_service": "Auth Service",
    "notify_service": "Notify Service",
    "products_service": "Products Service",
}

export const LogSourceNone = ""
export const LogSourceSystem = "system"
export const LogSourceBHAdmin = "bh_admin"
export const LogSourceDebug = "dev_debug"
export const LogSourceAuthService = "auth_service"
export const LogSourceLoggerService = "logger_service"
export const LogSourceNotifyService = "notify_service"
export const LogSourceProductsService = "products_service"
export const LogSourceEcosystemService = "eco_service"
export const LogSourceIntegrationsService = "integrations_service"
export const LogSourceServicesService = "services_service"
export const LogSourceIntegrationMoreFlo = "integration_more_flo"
export const LogSourceIntegrationSlim4 = "integration_slim4"
export const LogSourceIntegrationClickUp = "integration_clickup"
export const LogSourceIntegrationProductFeedsV2 = "integration_feeds_v2"
export const LogSourceIntegrationGamifieraUserFeedV2 = "integration_gamifiera_user_feed_v2"
export const LogSourceIntegrationProductReplicatorV1 = "integration_replicator_v1"
export const LogSourceRedisProductReplicatorV3 = "redis_product_replicator_v3"
export const LogSourceReplicatorVoyadoUIDs = "replicator_voyado_uids"